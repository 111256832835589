import { SearchDocumentType } from '@wix/client-search-sdk';
import React from 'react';
import { GridLayout } from '../components/SearchResults/Widget/components/GridLayout';
import { ListLayoutItemProps } from '../components/SearchResults/Widget/components/Layout.types';
import { LayoutItem } from '../components/SearchResults/Widget/components/LayoutItem.types';
import { ListLayout } from '../components/SearchResults/Widget/components/ListLayout';
import { ProductItemDecorator } from './ecom/ProductItemDecorator';
import { WidgetComponentProps } from '../components/SearchResults/Widget/components/Widget';
import { ForumListItem } from './forum/ForumListItem/ForumListItem';
import { EventListItem } from './events/EventListItem';
import { ProGalleryItemDecorator } from './proGallery/ProGalleryItemDecorator';

export type ItemDecorationProps = {
  item: LayoutItem;
} & Partial<WidgetComponentProps>;

export type AppComponents = {
  Item?: React.ComponentType<ListLayoutItemProps>;
  ItemDecoration?: React.ComponentType<ItemDecorationProps>;
  extraProps?: (keyof WidgetComponentProps)[];
};

export enum LayoutType {
  Grid = 'grid',
  List = 'list',
}

type DocumentConfig = {
  layout: LayoutType;
};

export const layoutData: Partial<
  Record<SearchDocumentType, DocumentConfig | null>
> = {
  [SearchDocumentType.Pages]: {
    layout: LayoutType.List,
  },
  [SearchDocumentType.Products]: {
    layout: LayoutType.Grid,
  },
  [SearchDocumentType.ProGallery]: {
    layout: LayoutType.Grid,
  },
  [SearchDocumentType.Forums]: {
    layout: LayoutType.List,
  },
};

export const LayoutComponentMap = {
  [LayoutType.Grid]: GridLayout,
  [LayoutType.List]: ListLayout,
};

export const componentsMap: Partial<Record<SearchDocumentType, AppComponents>> =
  {
    [SearchDocumentType.Products]: {
      ItemDecoration: ProductItemDecorator,
      extraProps: ['onProductAddToCart'],
    },
    [SearchDocumentType.ProGallery]: {
      ItemDecoration: ProGalleryItemDecorator,
    },
    [SearchDocumentType.Forums]: {
      Item: ForumListItem,
    },
    [SearchDocumentType.Events]: {
      Item: EventListItem,
    },
  };
